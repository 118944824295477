<template>
  <div class="myStatistics-index">
    <headCard :type='type' />
    <overview :state="state" />
    <backlog :state="state" :restChart="restChart" />
    <totalOrder :state="state" :type='type' />
    <discount :sevenDataArr='sevenDataArr' />
  </div>
</template>

<script>
import headCard from "./components/headCard";
import overview from "./components/overview";
import backlog from "./components/backlog";
import totalOrder from "./components/totalOrder";
import discount from "./components/discount";
import {
  getPcCount,
  countCustomerAnalysis,
  sevenData,
} from "@/api/myStatistics";
import {getDataTimeSec} from "@/utils/index"
export default {
  name: "myStatistics",
  components: {
    headCard,
    overview,
    backlog,
    totalOrder,
    discount,
  },
  props: {
    type: {
      type: Number,
      default: 1,
    },
  },
  created() {
    this.getPcCount();
    this.countCustomerAnalysis();
    // this.sevenData();
  },
  data() {
    return {
      state: {},
      restChart: [],
      sevenDataArr:[]
    };
  },
  methods: {
    async getPcCount() {
      const reslut = await getPcCount({ type: this.type });
      console.log(reslut)
      if(reslut.data.newCustomerList){
        reslut.data.newCustomerList.map(val =>{
          val.createTime = getDataTimeSec(val.createTime)
        })

      }
      this.state = reslut.data;
    },
    async countCustomerAnalysis() {
      const result = await countCustomerAnalysis({ type: this.type });
      this.restChart = result.data;
    },
    async sevenData() {
      const result = await sevenData({ type: this.type });
      const { orderList, dealList } = result.data;
      if(orderList.length > 0){
        orderList.map(item =>{
          if(item.amount){
            item.amount = (item.amount/100).toFixed(2)
          }
        })
      }
      if(dealList.length > 0){
        dealList.map(item =>{
          if(item.amount){
            item.amount = (item.amount/100).toFixed(2)
          }
        })
      }

      orderList.map((item) => {
        item.city = "商城订单";
      });
      dealList.map((item) => {
        item.city = "个人订单";
      });
      this.sevenDataArr = orderList.concat(dealList);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
