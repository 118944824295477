<template>
  <div class="dataOverview-index">
    <div style="background: #ffffff">
      <div class="dingdan_title">
        <div style="width: 4px;height: 16px;background: #51CBCD;"></div>
        <div>
          订单概况
        </div>
      </div>
      <div class="card-grid">
        <div>
          <img src="@/assets/images/dd1.png" alt="">
          <div class="card_item">
            <div>
              <span>{{ state.orderAmount | formatMoney }}</span>
              <span>元</span>
            </div>
            <div>订单总额</div>
          </div>
        </div>
        <div>
          <img src="@/assets/images/dd2.png" alt="">
          <div class="card_item">
            <div>
              <span>{{ state.orderCount || 0 }}</span>
              <span>单</span>
            </div>
            <div>订单数量</div>
          </div>
        </div>
        <div>
          <img src="@/assets/images/dd3.png" alt="">
          <div class="card_item">
            <div>
              <span>{{ state.todayOrderCount || 0 }}</span>
              <span>单</span>
            </div>
            <div>今日订单</div>
          </div>
        </div>
        <div>
          <img src="@/assets/images/dd4.png" alt="">
         <div class="card_item">
           <div>
             <span>{{ state.totalRefundMoney | formatMoney }}</span>
             <span>元</span>
           </div>
           <div>退款总额</div>
         </div>
        </div>
        <div>
          <img src="@/assets/images/dd5.png" alt="">
          <div class="card_item">
            <div>
              <span>{{ state.totalRefundCount }}</span>
              <span>单</span>
            </div>
            <div>退款数量</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "overview",
  data() {
    return {
      timeDate: ["累计", "今日", "近7天"],
      value: "",
    };
  },
  props: {
    state: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
  },
  filters: {
    formatMoney(val) {
      return val ? (val / 100).toFixed(2) : 0;
    },
    dealMoney(val) {
      return val.targetMoney <= val.dealMoney
          ? 0
          : (val.targetMoney/100 - val.dealMoney/100).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-card {
  border: 0px;
}
@media screen and (max-width: 1200px) {
  .card-grid {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media screen and (max-width: 700px) {
  .card-grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media screen and (max-width: 700px) {
  .clearfix-right {
    display: none;
  }
}
.clearfix-right {
  .right-item {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #737f80;
    span {
      margin-right: 24px;
      cursor: pointer;
    }
  }
}
.bg {
  height: 120px;
  background: rgba(81, 203, 205, 0.08);
  border-radius: 2px;
  margin-bottom: 20px;
}
.card-content {
  padding: 0 24px;
  .icon-div {
    width: 72px;
    height: 72px;
    background: rgba(81, 203, 205, 0.15);
    border-radius: 24px;
    .icon {
      color: #51cbcd;
      font-size: 35px;
    }
  }
  .card-right {
    margin-left: 16px;
    & > span {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #737f80;
    }
    h3 {
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #454a4c;
      margin-top: 8px;
    }
    p {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #51cbcd;
      margin-top: 9px;
      margin-left: 8px;
    }
  }
}

.card-grid {
  display: flex;
  justify-content: flex-start;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 16px;
  padding: 20px 0;
  & > div {
    width: 20%;
    display: flex;
    margin-left: 30px;
  }
  & > div > img {
    width: 34px;
    height: 34px;
    align-self: center;
    margin-right: 10px;
  }
  & > div > .card_item {
    display: flex;
    flex-direction: column;
    & > div{
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #737F80;
      & > span:nth-child(1){
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #454A4C;
      }
      & > span:nth-child(2){
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #575B5D;
        margin-left: 8px;
      }
    }
  }
  & > p {
   border-right: 1px solid #bebdbd;
  }
}
.dropDown{
  position: relative;
}
.dropDown:hover .dropDownList{
  opacity: 1;
  transition: all 0.4s;
}
.dropDownList{
  opacity: 0;
  top: 120px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99;
  background: #ffffff;
  padding:10px 20px;
  width: 400px;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: all 0.4s;
}

.triangle-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
    position: absolute;
    top: -10px;
}
.today-entry{
  max-height: 400px;
}
.overview-name{
  display: block;
  width: 80px;
  white-space: nowrap;
  text-overflow:ellipsis;
  overflow: hidden;
  text-align: left;
}
.dingdan_title{
  border-bottom: 1px solid #EBEEF5;
  padding: 21px 0 21px 20px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
}
.dingdan_title>div:nth-child(1){
  align-self: center;
  margin-right: 4px;
}
.dingdan_title>div:nth-child(2){
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #454A4C;
}
</style>
