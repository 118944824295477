<template>
  <div>
    <el-row :gutter="24" style="margin: 0 -6px !important">
      <el-col
        style="padding: 0 8px !important"
        :lg="8"
        :md="12"
      >
        <div class="company-card d-flex flex-column justify-content-center">
          <div class="d-flex">
            <img class="company-logo" :src="data.assLogo" />
            <div class="d-flex flex-column company-name" style="align-self: center">
              <span>{{ data.assName }}</span>
<!--              <span>{{ data.phone }}</span>-->
            </div>
          </div>
          <div style="margin-top: 22px">
            <el-row :gutter="24" style="margin: 0 !important">
              <el-col :xl="20" :lg="18" :md="16" style="padding: 0 !important">
                <div class="company-time">登录时间：{{ loginDate  || '---' }}</div>
              </el-col>
              <el-col :xl="4" :lg="6" :md="8" style="padding: 0 !important">
                <!-- <div class="company-account" @click="$router.push({ path: '/corporateInformation' })">
                  <svg
                    class="icon"
                    aria-hidden="true"
                    style="margin-right: 5px"
                  >
                    <use xlink:href="#icon-edit"></use>
                  </svg>
                  <span>管理账户</span>
                </div> -->
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
      <el-col
        style="padding: 0 8px !important"
        :lg="4"
        :md="12"
      >
        <div
          class="business-card d-flex flex-column justify-content-center align-items-center"
        >
          <div>
            <i class="el-icon-postcard" style="margin-right: 8px"></i>
            <span>已入会成员数</span>
          </div>
          <span>{{  state.totalMemberCount  }}</span>
          <div style="display: flex;justify-content: space-evenly;width: 100%">
            <span class="fs7 cfff"> 已缴<span style="margin-left:5px;font-size:14px;line-height:5px">{{state.payCount}}</span></span>
            <span style="border-right: 1px solid #fbfbfb"></span>
            <span class="fs7 cfff"> 未缴<span style="margin-left:5px;font-size:14px;line-height:5px">{{state.notPayCount}}</span></span>
          </div>

        </div>
      </el-col>
      <el-col
        style="padding: 0 8px !important"
        :lg="4"
        :md="12"
      >
        <div
          class="business-card d-flex flex-column justify-content-center align-items-center"
        >
          <div>
            <i class="el-icon-postcard" style="margin-right: 8px"></i>
            <span>平台游客数</span>
          </div>
          <span v-if="state.aiTouristCount">{{  state.aiTouristCount + state.crmTouristCount}}</span>
          <span v-else>0</span>
          <div style="display: flex;justify-content: space-evenly;width: 100%">
            <span class="fs7 cfff"> 名片端<span style="margin-left:5px;font-size:14px;line-height:5px">{{state.aiTouristCount}}</span></span>
            <span style="border-right: 1px solid #fbfbfb"></span>
            <span class="fs7 cfff"> CRM端<span style="margin-left:5px;font-size:14px;line-height:5px">{{state.crmTouristCount}}</span></span>
          </div>
        </div>
      </el-col>
      <el-col
        style="padding: 0 8px !important"
        :lg="8"
        :md="12"
      >
        <div
          class="authorization-card d-flex align-items-center"
          style="position: relative"
        >
          <div class="authorization-info d-flex flex-column">
            <div class="authorization-information">
              <i class="el-icon-info"></i>
              <span>授权信息</span>
            </div>
            <div class="authorization-time">
              <span>{{ data.createTime | getDataTimeSec }}</span>
            </div>
            <div class="authorization-text">这是您的授权开始时间</div>
          </div>
          <div id="chartView"></div>
        </div>
      </el-col>
      <!-- <el-col style="padding: 0 8px !important" :lg="6" :md="12">
        <div
          class="announcement-card d-flex flex-column justify-content-center"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="announcement-a">
              <i class="el-icon-message-solid"></i>
              <span>公告</span>
            </div>
            <div class="announcement-more">更多 ></div>
          </div>
          <div class="announcement-list">
            <div class="list-item d-flex align-items-center">
              <span>06-02</span>
              <span>18年6月02日重要更新公告22we</span>
            </div>
            <div class="list-item d-flex align-items-center">
              <span>06-02</span>
              <span>18年6月02日重要更新公告22we</span>
            </div>
          </div>
        </div>
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
import { fetchCompany } from "@/api/companyManage.js";
import {addAssociation, selectAssociationList, fetchIndustryList, upAssociation} from '@/api/organization.js'
import { getDataTimeSec } from "@/utils";
import { Chart } from "@antv/g2";
export default {
  data() {
    return {
      data: {},
      day:0,
      chartData: [{ type: "剩余天数", value: 0 }],
      userInfo: this.$store.state.loginRoot.userInfo,
      loginDate: this.$store.state.loginRoot.loginDate,
    };
  },
  props: {
    type: {
      type: Number,
      default: 1,
    },
    state: {
      type: Object,
      default: () => {},
    },
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
  },
  created() {
    this.fetchCompany();
  },
  mounted() {
  },
  methods: {
    /** */
    async fetchCompany() {
      let ajax = {
        companyId: localStorage.getItem("companyId"),
        cId:localStorage.getItem("companyId"),
      }
      try {
        const reslut = await fetchCompany(ajax);
        this.data = reslut.data;
        console.log('this.data',this.data)
        let createTime = new Date(this.data.createTime);
        let endTime = new Date(this.data.endTime);
        let days = endTime .getTime() - createTime .getTime();
        this.day = parseInt(days / (1000 * 60 * 60 * 24))

        this.chartData[0].value =  this.data.maturityDays;
        this.initComponent();
      } catch (error) {
        console.log(error);
      }
    },
    initComponent() {
      const chart = new Chart({
        container: "chartView",
        autoFit: true,
        height: 300,
        width: 300,
      });
      chart.data(this.chartData);
      chart.legend(false);
      chart.tooltip({
        showMarkers: false,
        position:'bottom'
      });
      chart.facet("rect", {
        fields: ["type"],
        padding: 20,
        showTitle: false,
        eachView: (view, facet) => {
          const data = facet.data;
          let color = "rgba(81, 203, 205, 1)";
          data.push({ type: "使用天数", value: this.day - data[0].value });
          view.data(data);
          view.coordinate("theta", {
            radius: 0.4,
            innerRadius: 0.8,
          });
          view
            .interval()
            .adjust("stack")
            .position("value")
            .color("type", [color, "#eceef1"])
            .style({
              opacity: 1,
            });
          view.annotation().text({
            position: ["50%", "55%"],
            content: data[0].type,
            style: {
              fontSize: 12,
              fill: "#8c8c8c",
              fontWeight: 300,
              textBaseline: "bottom",
              textAlign: "center",
            },
            offsetY: -12,
          });

          view.annotation().text({
            position: ["50%", "50%"],
            content: data[0].value,
            style: {
              fontSize: 18,
              fill: "#000",
              fontWeight: 500,
              textAlign: "center",
            },
            offsetY: 10,
          });

          view.interaction("element-active");
        },
      });
      chart.render();
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin global-card() {
  height: 140px;
  background: #ffffff;
  border-radius: 2px;
  padding: 0 24px;
  margin-bottom: 16px;
}
@mixin WidthHeight($width, $height) {
  width: $width;
  height: $height;
}
.company-card,
.business-card,
.authorization-card,
.announcement-card {
  @include global-card();
}
.company-card {
  .company-logo {
    @include WidthHeight(50px, 50px);
    margin-right: 10px;
  }
  .company-name {
    span {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 700;
      line-height: 23px;
      color: #454a4c;
      &:nth-of-type(2) {
        margin-top: 6px;
        font-weight: 400;
      }
    }
  }
  .company-time {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #737f80;
  }
  .company-account {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #51cbcd;
    cursor: pointer;
  }
}
.business-card {
  background: linear-gradient(90deg, #76dad9 0%, #51cbcd 100%);
  color: #ffffff;
  padding: 0;
  span {
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 16px;
  }
  & > span {
    font-size: 48px;
    margin-top: 3px;
  }
}
.authorization-card {
  .authorization-info {
    .authorization-information {
      i {
        color: #51cbcd;
        margin-right: 8px;
      }
      span {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #454a4c;
      }
    }
    .authorization-time {
      height: 28px;
      background: rgba(81, 203, 205, 0.04);
      border: 1px solid rgba(81, 203, 205, 0.30196078431372547);
      border-radius: 4px;
      padding: 0 14px;
      line-height: 28px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #51cbcd;
      margin-top: 10px;
    }
    .authorization-text {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #737f80;
      margin-top: 8px;
    }
  }
  #chartView {
    position: absolute;
    right: 0;
    bottom:-80px;
  }
}
.announcement-card {
  .announcement-a {
    i {
      color: #51cbcd;
      margin-right: 8px;
    }
    span {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #454a4c;
    }
  }
  .announcement-more {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #51cbcd;
    cursor: pointer;
  }
  .announcement-list {
    margin-top: 20px;
    .list-item {
      span {
        &:nth-of-type(1) {
          font-size: 12px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.45);
        }
        &:nth-of-type(2) {
          font-size: 14px;
          display: inline-block;
          width: 186px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          margin-left: 16px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      &:nth-of-type(2) {
        margin-top: 12px;
      }
    }
  }
}
</style>
