<template>
  <div style="background: #FFFFFF;margin-top: 20px">
    <div class="dingdan_title">
      <div class="dingdan_title_info">
        <div style="width: 4px;height: 16px;background: #51CBCD;"></div>
        <div>新增情况</div>
      </div>
      <div style="display: flex;align-self: center;margin-right: 20px">
        <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="dateValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="chooseDate"
            :picker-options="pickerOptions">
        </el-date-picker>
        <el-select
            style="width: 200px;margin-left: 10px"
            v-model="categoriesValue"
            placeholder="会员"
            @change="chooseJS"
        >
          <el-option
              v-for="(item, index) in options"
              :key="index.Id"
              :label="item.name"
              :value="item.Id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="view pa24" style="margin-top: 16px">
      <div id="container" style="width: 100%;height: 500px;"></div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import {quertByDategetData} from "@/api/myStatistics";

export default {
  data() {
    return {
      categoriesValue: 'member',
      data: [],
      dateValue: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      options: [{
        name: '会员', Id: 'member'
      }, {name: '游客', Id: 'youke'}],
      startTime: '',
      endTime: '',
      dataList: [],
      charts: '',
    };
  },
  mounted() {
    this.getdate()
    this.getData()
  },
  methods: {
    fun_date(aa) {
      var date1 = new Date()
          // time1 = date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate();//time1表示当前时间
      var date2 = new Date(date1);
      console.log(Number(date2.getDate()) < 10 ? '0' + Number(date2.getDate()) : Number(date2.getDate()))
      date2.setDate(date1.getDate() + aa);
      var time2 = date2.getFullYear() + "-" + (Number(date2.getMonth() + 1) < 10 ? '0' + Number(date2.getMonth() + 1) : Number(date2.getMonth() + 1)) + "-" + (Number(date2.getDate()) < 10 ? ('0' + Number(date2.getDate())) : Number(date2.getDate()));
      return time2
    },
    getdate() {
      let date1 = new Date()
      let time1 = date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate();//time1表示当前时间
      this.startTime = this.fun_date(-7)
      this.endTime = time1
    },
    async getData() {
      let ajax = {
        associationId: localStorage.getItem("associationId"),
        queryType: this.categoriesValue,
      }
      if (this.dateValue) {
        ajax.startTime = this.dateValue[0]
        ajax.endTime = this.dateValue[1]
      } else {
        ajax.startTime = this.startTime
        ajax.endTime = this.endTime
      }
      const reslut = await quertByDategetData(ajax);
      this.dataList = reslut.data
      this.initComponent();
    },
    chooseJS() {
      this.getData()
    },
    chooseDate() {
      this.getData()
    },
    initComponent() {
      this.charts = echarts.init(document.getElementById('container'))
      let date = []
      let data = []
      for (let i in this.dataList) {
        date.push(this.dataList[i].date)
        data.push(this.dataList[i].amount)
      }
      this.charts.setOption({
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['新增情况'],
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          // top:'3%',
          containLabel: true
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: date

        },
        yAxis: {
          type: 'value'
        },

        series: [{
          name: '新增情况',
          type: 'line',
          stack: '总量',
          data: data,
          itemStyle: {
            normal: {
              color: 'rgb(81, 203, 205)',
              lineStyle: {
                color: 'rgb(81, 203, 205)'
              }
            }
          },
        }]
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.dingdan_title {
  //border-bottom: 1px solid rgb(192, 188, 188);
  //padding: 10px 0 10px 20px;
  //font-size: 20px;
  //font-weight: bold;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #EBEEF5;
}

.dingdan_title_info {

  padding: 21px 0 21px 20px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
}

.dingdan_title_info > div:nth-child(1) {
  align-self: center;
  margin-right: 4px;
}

.dingdan_title_info > div:nth-child(2) {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #454A4C;
}
</style>
