<template>
  <div class="dataOverview-index" style="margin-top: 20px">
    <div style="background: #ffffff">
      <div class="dingdan_title">
        <div style="width: 4px;height: 16px;background: #51CBCD;"></div>
        <div>常用功能</div>
      </div>
      <div class="card-grid">
        <div @click="toPage('/membersWork')">
          <img src="@/assets/images/fb1.png" alt="">
          <span>发布文章</span>
        </div>
        <div @click="toPage('/membersList')">
          <img src="@/assets/images/fb2.png" alt="">
          <span>添加会员</span>
        </div>
        <div @click="toPage('/membership')">
          <img src="@/assets/images/fb3.png" alt="">
          <el-badge :is-dot="state.approvelMemberNum>0?true:false" class="item">入会审核</el-badge>
        </div>
        <div @click="toPage('/membersWork')">
          <img src="@/assets/images/fb4.png" alt="">
          <span>商会工作</span>
        </div>
        <div @click="toPage('/managementSh')">
          <img src="@/assets/images/fb5.png" alt="">
          <span>上传商品</span>
        </div>
        <div @click="toPage('/orderManagementSh')">
          <img src="@/assets/images/fb6.png" alt="">
          <span>查看订单</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "backlog",
  data() {
    return {

    };
  },
  props: {
    state: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    state(val) {
      console.log(val)
    },
  },
  methods:{
    toPage(path){
      this.$router.push({
        path: path,
        query: {},
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-card {
  border: 0px;
}
.card-grid>div{
  cursor: pointer;
}
@media screen and (max-width: 1200px) {
  .card-grid {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media screen and (max-width: 700px) {
  .card-grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media screen and (max-width: 700px) {
  .clearfix-right {
    display: none;
  }
}
.clearfix-right {
  .right-item {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #737f80;
    span {
      margin-right: 24px;
      cursor: pointer;
    }
  }
}
.bg {
  height: 120px;
  background: rgba(81, 203, 205, 0.08);
  border-radius: 2px;
  margin-bottom: 20px;
}
.card-content {
  padding: 0 24px;
  .icon-div {
    width: 72px;
    height: 72px;
    background: rgba(81, 203, 205, 0.15);
    border-radius: 24px;
    .icon {
      color: #51cbcd;
      font-size: 35px;
    }
  }
  .card-right {
    margin-left: 16px;
    & > span {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #737f80;
    }
    h3 {
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #454a4c;
      margin-top: 8px;
    }
    p {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #51cbcd;
      margin-top: 9px;
      margin-left: 8px;
    }
  }
}

.card-grid {
  display: flex;
  justify-content: flex-start;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 16px;
  padding: 20px;
  & > div {
    display: flex;
    background: rgba(81, 203, 205, .1);
    padding: 20px;
    width: 17%;
    & > img{
      width: 60px;
      height: 60px;
    }
    & > span:nth-child(2){
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #454A4C;
      align-self: center;
      margin-left: 20px;
    }
    & > .item{
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #454A4C;
      align-self: center;
      margin-left: 20px;
    }
  }
}
/deep/.el-badge__content.is-dot{
  height: 12px;
  width: 12px;
  right: 0;
  top: -3px;
}
.dropDown{
  position: relative;
}
.dropDown:hover .dropDownList{
  opacity: 1;
  transition: all 0.4s;
}
.dropDownList{
  opacity: 0;
  top: 120px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99;
  background: #ffffff;
  padding:10px 20px;
  width: 400px;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: all 0.4s;
}
.dingdan_title{
  border-bottom: 1px solid #EBEEF5;
  padding: 21px 0 21px 20px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
}
.dingdan_title>div:nth-child(1){
  align-self: center;
  margin-right: 4px;
}
.dingdan_title>div:nth-child(2){
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #454A4C;
}
</style>
