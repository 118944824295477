<template>
  <div class="container">
    <el-tabs class="tabs" v-model="activeName" v-if="companyType==1||companyType==2 || companyType==4">
      <el-tab-pane label="我的统计" name="first"><my-statistics :type="activeName == 'first' && 1" v-if="activeName == 'first'" /></el-tab-pane>
      <template v-if="userInfo.roleId || jurisdiction || userInfo.main">
        <el-tab-pane label="企业统计" name="second"><enterprise :type="activeName == 'second' && 2" v-if="activeName == 'second'" /></el-tab-pane>
      </template>
    </el-tabs>
    <div v-if="companyType=='444'">
      <sh-statistics  />
    </div>
  </div>
</template>

<script>
import myStatistics from './components/myStatistics';
import enterprise from './components/enterprise';
import shStatistics from './components/shStatistics';   //商户工作台
import {queryEmpRouter} from '@/api/companyManage';
import { mapState,mapMutations } from 'vuex';
export default {
  name: "dashboard",
  components:{
    myStatistics,
    enterprise,
    shStatistics
  },
  data() {
    return {
      userInfo: this.$store.state.loginRoot.userInfo,
      activeName:'first',
      jurisdiction:'',
      companyType:localStorage.getItem("companyType")
    };
  },
  computed: {
    ...mapState(['routerArr']),
  },
  watch:{
    routerArr(val){
      console.log(val)
      this.jurisdiction = val.includes('00')
    }
  },
  created(){

  },
  mounted() {
    if (this.roleId == 0) {
      document.title = localStorage.getItem("companyName");
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href =localStorage.getItem("companyLogo");
      document.getElementsByTagName('head')[0].appendChild(link);
    }else if (this.companyType == '444') {
      document.title = localStorage.getItem("associationName")
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = localStorage.getItem("logo");
      document.getElementsByTagName('head')[0].appendChild(link);
    }else{
      document.title = localStorage.getItem("companyName")?localStorage.getItem("companyName"):''
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = localStorage.getItem("companyLogo");
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  },
  methods:{
    // ...mapMutations(['setRouterArr','setRouterArr']),
    // handleClick(tab){
    //   const { name } = tab;
    //   this.activeName = name;
    // },
    // empRouter(){
    //   queryEmpRouter({a:''})
    //   .then(res=>{
    //     let arr = JSON.parse(res.data.routerId)
    //     this.setRouterArr(arr)
    //     this.setEmpId(res.data.empId)
    //     console.log(this.$store.state)
    //   })
    // }

  }
};
</script>

<style scoped lang='scss'>
/deep/.el-tabs__header{
  margin-bottom: 16px !important;
}
</style>
