import { render, staticRenderFns } from "./backlog.vue?vue&type=template&id=084b3789&scoped=true&"
import script from "./backlog.vue?vue&type=script&lang=js&"
export * from "./backlog.vue?vue&type=script&lang=js&"
import style0 from "./backlog.vue?vue&type=style&index=0&id=084b3789&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "084b3789",
  null
  
)

export default component.exports