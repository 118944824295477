import httpUtil from "@/utils/httpUtil";


/**获取订单列表 */
export const getPcCount = async params => httpUtil.post("/api/crmPc/index/getPcCount", params);

/**客户兴趣 */
export const countCustomerAnalysis =  async params => httpUtil.post("/api/crmPc/index/countCustomerAnalysis", params);


/**折线图 */
export const sevenData = params => httpUtil.post("/api/crmPc/index/sevenData", params);

/**漏斗数据 */
export const getChangeCount = params => httpUtil.post("/api/crmPc/index/getChangeCount", params);



/**会员数据 */
export const quertByDategetData = params => httpUtil.post("/api/crmPc/home/quertByDategetData", params);
